import { PropsWithChildren, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { CloseButton } from "./CloseButton";

export type ModalProps = {
  title: string;
  onClose?: () => void;
  closeable?: boolean;
};

export function Modal({
  title,
  closeable,
  onClose,
  children,
}: PropsWithChildren<ModalProps>) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="fixed z-50 w-full h-full bg-gray-300 bg-opacity-65 top-0 left-0 rounded-md font-poppins">
      <section
        className="m-2 bg-white max-w-[450px] mx-auto my-0"
        style={{
          height: "calc(100% - 1rem)",
        }}
      >
        <section className="flex p-2 [&>button]:m-3 border-b-2 border-[#00DABE] font-jdmeregular">
          {title} {closeable && onClose && <CloseButton onClick={onClose} />}
        </section>
        <div
          className="p-2 overflow-auto"
          style={{
            maxHeight: "480px",
            paddingBottom: "60px",
          }}
        >
          {children}
        </div>
      </section>
    </div>,
    document.getElementById("main") as HTMLElement
  );
}
